

































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Blank extends Vue {
  @Prop({ default: false, type: Boolean })
  complete!: boolean;

  @Prop({ default: false, type: Boolean })
  current!: boolean;

  @Prop({ default: false, type: Boolean})
  solid !: boolean;

  @Prop({ default: false, type: Boolean})
  locked !: boolean;

  get icon() {
    if (this.solid && this.complete){ 
      return 'fa-solid fa-check-circle';
    }else{
      return `fa-light ${this.complete ? "fa-check-circle" : "fa-circle"}`;
    }
  }
}
